<template>
  <div id="member-point-page">
    <div class="inner-acc">
      <div class="wrapper rowflex">
        <sidebar-account></sidebar-account>
        <article class="main">
          <div class="member-point">
            <div class="card">
              <div
                class="layer"
                style="background-image: url('/img/dummy/point/1.jpg');"
              >
                <div class="hcard clearfix">
                  <img src="/img/dummy/point/2.png" width="95" />
                  <a href="#" class="cvr-bg-af">What is Member Points</a>
                </div>
                <strong>1.000 <small>Points</small></strong>
                <b>John Asegaf</b>
              </div>
              <div class="info">
                <small>Points Closest to Expiry</small>
                <em>100 Points (12 Mar 2021)</em>
              </div>
            </div>
            <!-- end of card -->

            <div class="history">
              <div class="hentry">
                <h2>Point History</h2>
              </div>
              <div class="thead">
                <table>
                  <tr>
                    <td>Invoice</td>
                    <td>Date</td>
                    <td>Transaction</td>
                    <td>Points</td>
                  </tr>
                </table>
              </div>
              <div class="tbody">
                <div class="scrollbar-inner">
                  <table>
                    <tr>
                      <td><a href="#">465759238955</a></td>
                      <td>20 March 2021</td>
                      <td>Rent-to-Own</td>
                      <td><b>+Rp340.000</b></td>
                    </tr>
                    <tr>
                      <td><a href="#">461783649238955</a></td>
                      <td>20 March 2021</td>
                      <td>Reward</td>
                      <td><b>+Rp300.000</b></td>
                    </tr>
                    <tr>
                      <td><a href="#">465759232835</a></td>
                      <td>20 April 2021</td>
                      <td>Buy</td>
                      <td><b>+Rp340.000</b></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!-- end of history -->

            <div class="redeem">
              <div class="hentry">
                <h2>Redeem Points</h2>
                <p>Select the item to be exchanged.</p>
              </div>
              <div class="filter">
                <form class="clearfix">
                  <div class="left">
                    <fieldset>
                      <input type="text" value="" placeholder="Min point" />
                    </fieldset>
                    <fieldset>
                      <input type="text" value="" placeholder="Max point" />
                    </fieldset>
                    <fieldset>
                      <div class="fselect fcheck">
                        <span>Items</span>
                        <ul>
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                name="items"
                                value="Furniture"
                                checked="checked"
                              />
                              <b>Furniture</b>
                            </label>
                          </li>
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                name="items"
                                value="Electronics"
                              />
                              <b>Electronics</b>
                            </label>
                          </li>
                          <li>
                            <label
                              ><input
                                type="checkbox"
                                name="items"
                                value="Home Decoration"
                              /><b>Home Decoration</b></label
                            >
                          </li>
                          <li>
                            <label
                              ><input
                                type="checkbox"
                                name="items"
                                value="Food"
                              /><b>Food</b></label
                            >
                          </li>
                          <li>
                            <label
                              ><input
                                type="checkbox"
                                name="items"
                                value="Others"
                              /><b>Others</b></label
                            >
                          </li>
                        </ul>
                      </div>
                    </fieldset>
                  </div>
                  <div class="right">
                    <div class="fselect">
                      <span>Sort</span>
                      <ul>
                        <li><a href="#">Featured</a></li>
                        <li><a href="#">Lowest Points</a></li>
                        <li><a href="#">Highest Points</a></li>
                      </ul>
                      <input type="hidden" name="sort" />
                    </div>
                  </div>
                </form>
              </div>
              <!-- end of filter -->

              <div class="list">
                <template v-for="n in 16">
                  <div class="item" :key="n">
                    <a
                      href="javascript:"
                      data-src="#popup-redeem"
                      data-fancybox
                    >
                      <figure><img src="/img/dummy/point/3.jpg" /></figure>
                      <div class="caption">
                        <h3>Rak {{ n + 1 }} Susun</h3>
                        <p>Unit rak, white, 60x148 cm</p>
                        <strong
                          >Rp {{ Math.floor(Math.random() * 300) }}.000
                          Points</strong
                        >
                      </div>
                    </a>
                  </div>
                </template>
              </div>
              <!-- end of list -->
              <br />
              <div class="pagination">
                <a href="#" class="cvr-bg-bf ic-arrow-left inactive"></a>
                <span>1</span>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#" class="cvr-bg-bf ic-arrow-right"></a>
              </div>
              <!-- end of pagination -->
            </div>
            <!-- end of redeem -->
          </div>
          <!-- end of acc member point -->

          <div id="popup-redeem" class="popup-redeem" style="display: none;">
            <div class="holder">
              <div class="big">
                <div class="slick-carousel">
                  <figure><img src="/img/dummy/point/4.jpg" /></figure>
                  <figure><img src="/img/dummy/point/5.jpg" /></figure>
                  <figure><img src="/img/dummy/point/6.jpg" /></figure>
                </div>
                <!-- end of slick -->
              </div>
              <!-- end of big -->
              <div class="caption">
                <div class="phead">
                  <h2>Rak 4 Susun</h2>
                  <p>Unit rak, white, 60x148 cm</p>
                  <strong>Rp 299.000 Points</strong>
                </div>
                <span>Ukuran: <b>60X148 CM</b></span>
                <div class="small">
                  <h3>Varian:</h3>
                  <div class="slick-carousel">
                    <figure>
                      <img src="/img/dummy/point/7.jpg" width="56" />
                      <figcaption>White</figcaption>
                    </figure>
                    <figure>
                      <img src="/img/dummy/point/8.jpg" width="56" />
                      <figcaption>Black</figcaption>
                    </figure>
                    <figure>
                      <img src="/img/dummy/point/9.jpg" width="56" />
                      <figcaption>Gold</figcaption>
                    </figure>
                  </div>
                  <!-- end of slick -->
                </div>
                <!-- end of slider -->
                <router-link to="/account/member-point/detail" class="button">
                  Redeem
                </router-link>
              </div>
              <!-- end of caption -->
            </div>
            <!-- end of holder -->
          </div>
          <!-- end of popup redeem -->
        </article>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner mort -->
  </div>
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";
export default {
  name: "MemberPoint",
  metaInfo: {
    title: "Member Point"
  },
  components: {
    SidebarAccount
  }
};
</script>
